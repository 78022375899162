import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"

import { useGlobalDispatchContext } from "../utilities/context"
import Meta from "../utilities/seo"

import Splash from "../components/home/splash"
import CareTeam from "../components/home/care-team"
import CareClients from "../components/home/care-clients"
import Testimonials from "../components/home/testimonials"
import Action from "../components/home/action"
import { graphql } from "gatsby"

export default ({ data }) => {
  const dispatch = useGlobalDispatchContext()

  const [splash, splashVisible] = useInView()
  const [team, teamVisible] = useInView({ threshold: 0.5 })
  const [clients, clientsVisible] = useInView()
  const [quotes, quotesVisible] = useInView()

  //data
  let node = data.prismic.allHomes.edges[0].node

  useEffect(() => {
    //set logo theme and position
    if (splashVisible) {
      dispatch({
        type: "toggle_logo_theme",
        logoTheme: "cream",
      })

      dispatch({
        type: "offset_logo",
        logoPosition: "default",
      })
    }

    //toggle menu visiblity
    if (teamVisible || clientsVisible || quotesVisible) {
      dispatch({
        type: "toggle_menu_view",
        menuView: "visible",
      })
    } else {
      dispatch({
        type: "toggle_menu_view",
        menuView: "hidden",
      })
    }
  })

  return (
    <>
      <Meta title="Switch40 - UK staffing agency" />

      <section ref={splash}>
        <Splash
          title={node.title[0].text}
          byline={node.byline[0].text}
          slidesA={node.slideshow_a}
          slidesB={node.slideshow_b}
          slidesC={node.slideshow_c}
        />
      </section>

      <section ref={team}>
        <CareTeam
          image={node.team_image}
          list={node.team_list}
          summary={node.team_description}
        />
      </section>

      <section ref={clients}>
        <CareClients
          image={node.clients_image}
          list={node.client_list}
          summary={node.clients_description}
        />
      </section>

      <section ref={quotes}>
        <Testimonials quotes={node.quote} />
      </section>

      <Action
        workCall={node.work_call}
        workImage={node.work_image}
        staffCall={node.staff_call}
        staffImage={node.staff_image}
      />
    </>
  )
}

export const query = graphql`
  {
    prismic {
      allHomes {
        edges {
          node {
            title
            byline
            slideshow_a {
              image
            }
            slideshow_b {
              image
            }
            slideshow_c {
              image
            }
            team_image
            team_description
            team_list {
              team_list_item
            }
            clients_image
            clients_description
            client_list {
              client_list_item
            }
            quote {
              bio_name
              photo
              author_title
              testimony
            }
            work_call
            work_image
            staff_image
            staff_call
            whatsapp_number
            telephone
            email
            address
          }
        }
      }
    }
  }
`
