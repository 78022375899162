import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Arrow from "../assets/images/icons/arrow-up.png"

const Box = styled.div`
  color: ${props => props.theme.cream};
  text-align: center;
  display: inline-block;
  background: ${props => props.theme.gold};
  overflow: hidden;
  position: relative;
  transition: color 0.5s;

  &:after {
    content: "";
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: ${props => props.theme.kaki};
    z-index: 0;
    transition: opacity 0.5s;
    opacity: 0;
    pointer-events: none;
  }

  a {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 2.25rem;
    height: 10.474vw;
  }

  span {
    display: block;
    padding-bottom: 1rem;
    width: 100%;
  }

  span,
  figure {
    position: relative;
    z-index: 1;
  }

  figure {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 0.75rem;
    width: 0.75rem;
    background-image: url(${Arrow});
  }

  &:hover {
    color: ${props => props.theme.blue};

    &:after {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    a {
      height: 20.533vw;
    }
  }
`

export default ({ to, title }) => (
  <Box>
    <Link to={to}>
      <span>{title}</span>
      <figure></figure>
    </Link>
  </Box>
)
