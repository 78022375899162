import React, { useEffect, useRef } from "react"
import styled from "styled-components"

const Box = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
  overflow: hidden;

  &.is-visible {
    background: ${props => props.theme.blue};
  }

  .content,
  .slide {
    transition: opacity 1.5s, visibility 1.5s;
  }

  .content {
    opacity: 0;
    visibility: hidden;

    &.is-visible {
      opacity: 1;
      visibility: visible;
      transition-delay: 0;
    }
  }

  .slide {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  @media (max-width: 767px) {
    overflow: visible;
  }
`
export default ({ children, duration, flexHeight, noFill }) => {
  const slider = useRef()

  useEffect(() => {
    var slideIndex = 0
    var sliderTimer = 0
    var bkgTimer = 0

    showSlides()

    function showSlides() {
      var i
      var slides = slider.current.querySelectorAll(".slide")

      for (i = 0; i < slides.length; i++) {
        slides[i].querySelector(".content").classList.remove("is-visible")
        slides[i].classList.add("is-hidden")
      }
      slideIndex++

      if (slideIndex > slides.length) {
        slideIndex = 1
      }

      var activeSlide = slides[slideIndex - 1]
      activeSlide.classList.remove("is-hidden")
      activeSlide.querySelector(".content").classList.add("is-visible")

      sliderTimer = setTimeout(showSlides, duration)
    }

    if (!noFill) {
      bkgTimer = setTimeout(() => {
        slider.current.classList.add("is-visible")
      }, 3000)
    }

    if (flexHeight) {
      let sliderHeight = 0
      let slides = slider.current.querySelectorAll(".slide p")
      let quoteBox = document.querySelector(".quoteBox")

      slides.forEach(element => {
        let slideHeight = element.offsetHeight

        if (slideHeight > sliderHeight) {
          sliderHeight = slideHeight
        }
      })

      quoteBox.style.minHeight = sliderHeight + "px"
    }

    return () => {
      clearTimeout(sliderTimer)
      clearTimeout(bkgTimer)
    }
  }, [])

  return <Box ref={slider}>{children}</Box>
}
