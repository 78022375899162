import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

import Arrow from "../../assets/images/icons/arrow-up.png"

import { Img } from "../objects"
import FadeUp from "../fade-in-up"

const Actions = styled.section`
  min-height: 50vw;
  position: relative;
  color: ${props => props.theme.cream};

  h2 {
    font-size: 3rem;
  }

  &:after {
    content: "";
    height: 100%;
    background: ${props => props.theme.black};
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
  }
`

const ActionsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 9.398vw 0;

  &:after {
    content: "";
    width: 1px;
    height: 30%;
    background: ${props => props.theme.kaki};
    position: absolute;
    left: 50%;
    z-index: 1;
  }
  h3 {
    padding-bottom: 3rem;
    color: ${props => props.theme.kaki};
    font-size: 1.8rem;
  }

  p {
    padding-bottom: 3rem;
  }

  span {
    display: inline-block;
    font-weight: 400;
    padding-bottom: 0.817vw;
    border-bottom: solid 1px ${props => props.theme.kaki};
  }

  .icon {
    position: absolute;
    height: 1.835vw;
    width: 1.835vw;
    top: 1.75rem;
    left: -5.734vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 75%;
    background-image: url(${Arrow});
  }

  .buttonWrap {
    width: 23.242vw;
    margin-left: 12.309vw;
  }

  @media (max-width: 767px) {
    padding-top: 0;
    display: block;

    &:after {
      display: none;
    }

    .buttonWrap {
      width: 57.067vw;
      padding-top: 22.4vw;
    }

    .icon {
      display: none;
    }
  }
`

const Action = styled.section`
  color: #fff;
  text-align: left;
  position: relative;
  z-index: 1;
  width: 50%;
  display: block;

  a {
    display: block;
    padding: 1.5rem 0;
    position: relative;
    transition: transform 0.5s, opacity 0.5s;

    &:hover {
      transform: translate(0.5rem, 0);
      opacity: 0.5;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 15vw;

    &:first-of-type {
      .buttonWrap {
        margin-left: 36.267vw;
      }
    }

    &:last-of-type {
      .buttonWrap {
        margin-left: 6.4vw;
      }
    }
  }
`

const Photo = styled.figure`
    position: absolute;
    overflow: hidden;
    width: 19.037vw;
    z-index: 0;
    height: 19.037vw;
    top:-30vw;
    background: ${props => props.theme.gold};
    transition: transform 0.5s;

    ${props =>
      props.left &&
      css`
        left: 0;
        top: -32vw;
      `}

    ${props =>
      props.right &&
      css`
        right: 0;
        top: -25vw;
      `}

    ${props =>
      props.hovered === "work" &&
      css`
        ${props =>
          props.left &&
          css`
            transform: translateY(5vw);
          `}
      `}

    ${props =>
      props.hovered === "staff" &&
      css`
        ${props =>
          props.right &&
          css`
            transform: translateY(5vw);
          `}
      `}

    @media (max-width: 767px){
        width: 33.600vw;
        height: 44.800vw;
        top: 0;

        ${props =>
          props.left &&
          css`
            top: 0;
          `}

        ${props =>
          props.right &&
          css`
            top: 0;
          `}
    }
`

const Title = styled.h2`
  margin-left: 25.994vw;
  padding-top: 7.416vw;
  width: 35.703vw;

  @media (max-width: 767px) {
    padding: 17.6vw 0;
    text-align: center;
    width: auto;
    margin: 0 auto;
  }
`

export default ({ workCall, workImage, staffCall, staffImage }) => {
  const [hovered, isHovered] = useState()

  // const [actions, actionsInView] = useInView()
  // const title = useRef()

  // useEffect(() => {
  //     let fill = document.querySelector('.quoteFill')

  //     if(actionsInView) {
  //         window.addEventListener("scroll", function(){
  //             let opacity = 1.5 - (title.current.getBoundingClientRect().top / window.innerHeight)

  //             if(opacity > 0 && opacity < 1.5){
  //                 fill.style.opacity = opacity
  //             }

  //         }, true)
  //     }

  //     return () => {
  //         window.removeEventListener("scroll", () => {return null}, true);
  //     }

  // }, [actionsInView])

  return (
    <Actions>
      <Title>
        <FadeUp delay={0.25}>
          <span>Looking for...</span>
        </FadeUp>
      </Title>

      <ActionsWrap>
        <Action>
          <Photo left hovered={hovered}>
            <FadeUp>
              <Img src={workImage.url} alt="" />
            </FadeUp>
          </Photo>

          <div className="buttonWrap">
            <FadeUp delay={0.3}>
              <h3>Work</h3>
              <p>{workCall[0].text}</p>

              <Link
                to="/find-work"
                onMouseEnter={() => {
                  isHovered("work")
                }}
                onMouseLeave={() => {
                  isHovered()
                }}
              >
                <span>Join our team</span>
                <figure className="icon"></figure>
              </Link>
            </FadeUp>
          </div>
        </Action>

        <Action>
          <Photo right hovered={hovered}>
            <FadeUp>
              <Img src={staffImage.url} alt="" />
            </FadeUp>
          </Photo>

          <div className="buttonWrap">
            <FadeUp delay={0.5}>
              <h3>Staff</h3>
              <p>{staffCall[0].text}</p>

              <Link
                to="/find-staff"
                onMouseEnter={() => {
                  isHovered("staff")
                }}
                onMouseLeave={() => {
                  isHovered()
                }}
              >
                <span>We are happy to assist</span>
                <figure className="icon"></figure>
              </Link>
            </FadeUp>
          </div>
        </Action>
      </ActionsWrap>
    </Actions>
  )
}
