import React from "react"
import styled from "styled-components"

import useWindowType from "../../utilities/useWindowType"

import Slideshow from "../slideshow"
import { Img, Flex } from "../objects"
import FadeUp from "../fade-in-up"
import { shuffle } from "../../utilities/shuffleArray"

const Wrap = styled.section`
  position: relative;
  min-height: 100vh;
  padding: 15vw 0 22vw 13.685vw;
  background-color: ${props => props.theme.dark};
  z-index: 1;

  &.portrait {
    min-height: 50vw;
  }

  @media (max-width: 767px) {
    padding-right: 0;
    padding-left: 6.4vw;
    min-height: 0;
    overflow: hidden;
  }
`
const Bkg = styled.figure`
  background-color: ${props => props.theme.sky};
  position: absolute;
  top: 0;
  left: 0;
  width: 41.774vw;
  height: 42.694vw;
  z-index: -1;

  @media (max-width: 767px) {
    width: 100vw;
    height: 64.267vw;
  }
`

const Bio = styled.div`
  width: 12.309vw;
  color: ${props => props.theme.black};

  span {
    display: block;
    font-size: 0.875rem;
    padding-right: 0.75rem;
    line-height: 1.4;

    &:last-child {
      opacity: 0.75;
    }
  }

  p {
    padding-top: 1rem;
    line-height: 1.5;
  }

  @media (max-width: 767px) {
    width: 26.933vw;
    color: ${props => props.theme.blue};
  }
`
const BioPhoto = styled.figure`
  width: 100%;
  height: 13.303vw;
  background-color: ${props => props.theme.kaki};

  @media (max-width: 767px) {
    height: 28.93vw;
  }
`

const Quote = styled.blockquote`
  background: #fff;
  width: 74.006vw;
  min-height: 42.572vw;
  border-bottom: solid 1px ${props => props.theme.kaki};

  p {
    font-size: 3rem;
    font-weight: bold;
    color: ${props => props.theme.blue};
    width: 57.339vw;
    font-family: "Playfair Display", serif;
    line-height: 1.45;
    padding: 3.982vw;
    padding-right: 0;
    padding-bottom: 6vw;
    position: relative;
  }

  @media (max-width: 1000px) {
    p {
      font-size: 4.5vw;
    }
  }

  @media (max-width: 767px) {
    width: 71.733vw;

    p {
      width: 100%;
      padding-right: 6.4vw;
      padding-bottom: 12vw;
      font-size: 5vw;
      line-height: 8vw;
    }
  }
`

const Fill = styled.div`
  position: absolute;
  top: 18vw;
  left: 0;
  background: ${props => props.theme.black};
  height: 100%;
  width: 100%;
  z-index: -2;
  opacity: 0;
  transition: opacity 0.25s linear;
`

const QuoteWrap = styled.section`
  position: relative;
`

export default ({ quotes }) => {
  const windowType = useWindowType()
  const stories = shuffle(quotes)

  // const [wrap, wrapInView] = useInView()
  // const bkg = useRef()

  // useEffect(() => {

  //     if (wrapInView) {
  //         window.addEventListener("scroll", function () {
  //             let offset = (bkg.current.getBoundingClientRect().top / window.innerHeight)* 100

  //             bkg.current.style.transform = 'translateY('+offset+'px'+')'

  //         }, true)
  //     }

  //     return () => {
  //         window.removeEventListener("scroll", () => { return null }, true);
  //     }

  // }, [wrapInView])

  return (
    <Wrap className={windowType}>
      <QuoteWrap>
        <Flex>
          <Bio>
            <FadeUp>
              <Slideshow duration={10000} noFill>
                {stories.map((item, index) => (
                  <div className="slide" key={index}>
                    <div className="content">
                      <BioPhoto>
                        <Img
                          src={item.photo.url}
                          alt={item.bio_name[0].text}
                        ></Img>
                      </BioPhoto>
                      <p>
                        <span>{item.bio_name[0].text}</span>
                        <span>{item.author_title[0].text}</span>
                      </p>
                    </div>
                  </div>
                ))}
              </Slideshow>
            </FadeUp>
          </Bio>

          <Quote className="quoteBox">
            <FadeUp delay={0.5}>
              <Slideshow duration={10000} flexHeight noFill>
                {stories.map((item, index) => (
                  <div className="slide" key={index}>
                    <div className="content">
                      <p>{item.testimony[0].text}</p>
                    </div>
                  </div>
                ))}
              </Slideshow>
            </FadeUp>
          </Quote>
        </Flex>
      </QuoteWrap>
      <Bkg />
      <Fill className="quoteFill" />
    </Wrap>
  )
}
