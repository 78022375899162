import React from "react"
import styled from "styled-components"
import { RichText } from "prismic-reactjs"

import FadeUp from "../fade-in-up"

import { Img, Flex, List } from "../objects"
import Button from "../button"

import useWindowType from "../../utilities/useWindowType"

const Wrap = styled.section`
  min-height: 100vh;
  padding-top: 10.144vw;
  padding-left: 13.685vw;
  position: relative;
  overflow: hidden;

  &.portrait {
    min-height: 100vw;
  }

  @media (max-width: 767px) {
    padding: 10vw 6.4vw;
  }
`

const Title = styled.h2`
  line-height: 1.1;
  span {
    display: block;
  }
`

const Caption = styled.div`
  width: 35.703vw;
  padding: 7.339vw 0;

  p {
    padding-bottom: 3.593vw;

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Hero = styled.figure`
  width: 30.657vw;
  height: 43.119vw;
  position: relative;
  margin-top: 14.602vw;
  background-color: ${props => props.theme.gold};

  > div {
    z-index: 2;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80%;
    background: ${props => props.theme.kaki};
    left: -13.685vw;
  }

  @media (max-width: 767px) {
    width: 79.6vw;
    height: 69.6vw;
    margin-left: 26.267vw;

    &:after {
      left: -43.267vw;
    }
  }
`

const Story = styled.section`
  width: 50vw;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const ListWrap = styled.div`
  padding-left: 11.086vw;
  padding-right: 1.376vw;

  ul {
    padding-bottom: 10.003vw;
  }

  @media (max-width: 767px) {
    padding: 6.4vw;

    ul {
      padding-bottom: 20vw;
    }
  }
`

const Pattern = styled.span`
  position: absolute;
  bottom: -20%;
  font-size: 53.0625rem;
  color: red;
  left: -13.685vw;
  z-index: -1;
  width: 100%;
  font-family: "Playfair Display", serif;
  font-weight: bold;
  line-height: 1;
  color: ${props => props.theme.blue};
  opacity: 0.04;

  @media (max-width: 767px) {
    font-size: 25rem;
    bottom: -5%;
  }
`

export default ({ image, list, summary }) => {
  const windowType = useWindowType()

  return (
    <Wrap className={windowType}>
      <div>
        <Title>
          <FadeUp>
            <span>We care about</span>
            <span>our team</span>
          </FadeUp>
        </Title>
      </div>

      <Flex justify fluidOnMobile>
        <Hero>
          <FadeUp>
            <Img src={image.url} alt="" />
          </FadeUp>
        </Hero>

        <Story>
          <Caption>
            <FadeUp>
              <RichText render={summary} />
            </FadeUp>
          </Caption>

          <ListWrap>
            <FadeUp>
              <List>
                {list.map((item, index) => {
                  return <li key={index}>{item.team_list_item[0].text}</li>
                })}
              </List>
              <Button to="/find-work" title="Apply to Join Switch40" />
            </FadeUp>
          </ListWrap>
        </Story>
      </Flex>

      <Pattern>team</Pattern>
    </Wrap>
  )
}
