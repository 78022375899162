import React, { useEffect } from "react"
import styled, { keyframes } from "styled-components"

import { useInView } from "react-intersection-observer"
import { useGlobalDispatchContext } from "../../utilities/context"
import useWindowType from "../../utilities/useWindowType"

import { Img } from "../objects"
import FadeUp from "../fade-in-up"
import Button from "../button"
import Slideshow from "../slideshow"

const zoomIn = keyframes`
    0% {
        transform:scale3d(1,1,1)
    }

    50% {
        transform:scale3d(1.5,1.5,1.5)
    }

    100% {
        transform:scale3d(1,1,1)
    }
`

const Wrap = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  &.portrait {
    height: 110vw;
  }

  @media (max-width: 767px) {
    height: 223.2vw;
    overflow: visible;
  }
`

const Bkg = styled.figure`
  background-color: ${props => props.theme.blue};
  height: 100%;
  width: 74.006vw;
  position: absolute;
  overflow: hidden;

  > div {
    filter: grayscale(100%);
    opacity: 0.1;
    animation: ${zoomIn} 125s linear infinite both;
  }

  @media (max-width: 767px) {
    height: 184.533vw;
    width: 100vw;
  }
`

const Title = styled.div`
  position: absolute;
  width: 42.508vw;
  left: 13.685vw;
  bottom: 5.425vw;
  z-index: 3;
  color: ${props => props.theme.cream};
  overflow: hidden;

  h1 {
    font-size: 2.5rem;
    line-height: 1.5;
  }

  .portrait & {
    width: 55vw;
    bottom: 15vw;
  }

  @media (min-width: 1000px) {
    .portrait & {
      h1 {
        font-size: 3rem;
      }
    }
  }

  @media (max-width: 767px) {
    width: 86.933vw;
    top: 45.867vw;
    left: 6.4vw;
    line-height: 1.5;

    h1 {
      font-size: 5.2vw;
    }
  }
`

const Byline = styled.div`
  width: 75%;
  padding-top: 4vw;
  line-height: 1.8;
  font-family: "Poppins";
  font-weight: normal;

  > div {
    color: #fff;
    font-size: 1rem;
  }

  @media (max-width: 767px) {
    padding-top: 2rem;
    width: 100%;
  }
`

const Actions = styled.section`
  position: absolute;
  bottom: 0;
  left: 62.92vw;
  z-index: 4;
  background: ${props => props.theme.gold};

  > div {
    &:last-child {
      background-color: ${props => props.theme.golder};
    }
  }

  .portrait & {
    width: 50vw;
    right: 0;
    left: auto;
    display: flex;

    > div {
      width: 50%;
    }
  }

  @media (max-width: 767px) {
    left: 6.4vw;
    top: 122.267vw;
    width: 86.933vw;
    bottom: auto;

    > div {
      width: 50%;
    }
  }
`

const TopImg = styled.figure`
  width: 19.97vw;
  height: 38.802vh;
  position: absolute;
  z-index: 2;
  top: 7.339vw;
  left: 62.92vw;
  overflow: hidden;

  .portrait & {
    height: 30vw;
    width: 25vw;
    left: 55vw;
  }

  @media (max-width: 767px) {
    top: 156.267vw;
    left: 6.4vw;
    width: 41.6vw;
    height: 41.6vw;
  }
`

const RightImg = styled.figure`
  width: 15.966vw;
  height: 17.202vw;
  z-index: 3;
  position: absolute;
  right: 6.804vw;
  top: 22.324vw;
  overflow: hidden;

  .portrait & {
    top: 30vw;
  }

  @media (max-width: 767px) {
    top: 166.267vw;
    right: 6.4vw;
    width: 41.6vw;
    height: 41.6vw;
  }
`

export default ({ title, byline, slidesA, slidesB, slidesC }) => {
  const dispatch = useGlobalDispatchContext()
  const windowType = useWindowType()
  const [splash, splashInView] = useInView({ threshold: 0.8 })

  //inview
  useEffect(() => {
    if (splashInView) {
      dispatch({
        type: "toggle_logo_view",
        logoView: "visible",
      })
    } else {
      dispatch({
        type: "toggle_logo_view",
        logoView: "hidden",
      })
    }
  }, [splashInView])

  return (
    <Wrap ref={splash} className={windowType}>
      <Title>
        <h1>
          <FadeUp time={1.5}>{title}</FadeUp>
        </h1>
        <Byline>
          <div>
            <FadeUp time={1.5} delay={0.2}>
              {byline}
            </FadeUp>
          </div>
        </Byline>
      </Title>
      <Actions>
        <Button to="/find-work" title="Looking for Work" />
        <Button to="/find-staff" title="Looking for Staff" />
      </Actions>
      <TopImg>
        <FadeUp time={1.3} delay={0.4}>
          <Slideshow duration={9000}>
            {slidesC.map((ele, index) => {
              return (
                <div key={index} className="slide">
                  <Img alt="" className="content" src={ele.image.url} />
                </div>
              )
            })}
          </Slideshow>
        </FadeUp>
      </TopImg>
      <RightImg>
        <FadeUp time={1.3} delay={0.5}>
          <Slideshow duration={8000}>
            {slidesB.map((ele, index) => {
              return (
                <div key={index} className="slide">
                  <Img alt="" className="content" src={ele.image.url} />
                </div>
              )
            })}
          </Slideshow>
        </FadeUp>
      </RightImg>
      <Bkg>
        <Slideshow duration={6000}>
          {slidesA.map((ele, index) => {
            return (
              <div key={index} className="slide">
                <Img alt="" className="content" src={ele.image.url} />
              </div>
            )
          })}
        </Slideshow>
      </Bkg>
    </Wrap>
  )
}
