import React from "react"
import styled from "styled-components"
import { RichText } from "prismic-reactjs"

import { Img, Flex, List } from "../objects"
import Button from "../button"
import FadeUp from "../fade-in-up"

import useWindowType from "../../utilities/useWindowType"

const Wrap = styled.section`
  min-height: 100vh;
  padding-top: 10.144vw;
  padding-left: 13.685vw;
  position: relative;
  color: ${props => props.theme.cream};
  overflow: hidden;

  &.portrait {
    min-height: 100vw;
  }

  &:after {
    content: "";
    height: 100%;
    width: 100vw;
    position: absolute;
    background: ${props => props.theme.blue};
    top: 0;
    left: 0;
    z-index: -2;
  }

  @media (max-width: 767px) {
    padding: 20vw 6.4vw;
  }
`

const Title = styled.h2`
  line-height: 1.1;

  span {
    display: block;
  }
`

const Caption = styled.div`
  padding-top: 8.716vw;

  p {
    padding-bottom: 3.593vw;

    &:last-of-type {
      padding-bottom: 0;
    }
  }
`

const Hero = styled.figure`
  width: 23.394vw;
  height: 27.523vw;
  position: relative;
  margin-top: 8.716vw;
  margin-right: 13.685vw;
  background-color: ${props => props.theme.gold};

  > div {
    z-index: 2;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -40%;
    width: 100%;
    height: 80%;
    background: ${props => props.theme.black};
    right: -12.309vw;
  }

  @media (max-width: 767px) {
    width: 59.733vw;
    height: 59.733vw;
    margin-top: 18vw;
    margin-bottom: 12vw;

    &:after {
      right: -28.31vw;
    }
  }
`

const Story = styled.section`
  width: 35.703vw;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const ListWrap = styled.div`
  ul {
    padding: 10vw 0;
  }

  @media (max-width: 767px) {
    padding: 6.4vw;

    ul {
      padding-bottom: 20vw;
    }
  }
`

const Pattern = styled.span`
  position: absolute;
  bottom: -20%;
  font-size: 53.0625rem;
  color: red;
  left: -13.685vw;
  z-index: -1;
  width: 100%;
  font-family: "Playfair Display", serif;
  font-weight: bold;
  line-height: 1;
  color: ${props => props.theme.cream};
  opacity: 0.05;

  @media (max-width: 767px) {
    font-size: 25rem;
    bottom: -5%;
  }
`

export default ({ image, summary, list }) => {
  const windowType = useWindowType()

  return (
    <Wrap className={windowType}>
      <header>
        <Title>
          <FadeUp>
            <span>We care about</span>
            <span>our clients</span>
          </FadeUp>
        </Title>
      </header>

      <Flex justify reverse fluidOnMobile>
        <Hero>
          <FadeUp>
            <Img src={image.url} alt="" />
          </FadeUp>
        </Hero>

        <Story>
          <Caption>
            <FadeUp>
              <RichText render={summary} />
            </FadeUp>
          </Caption>

          <ListWrap>
            <FadeUp>
              <List>
                {list.map((item, index) => (
                  <li key={index}>{item.client_list_item[0].text}</li>
                ))}
              </List>
              <Button to="/find-staff" title="Make Inquiry for Staff" />
            </FadeUp>
          </ListWrap>
        </Story>
      </Flex>

      <Pattern>clients</Pattern>
    </Wrap>
  )
}
